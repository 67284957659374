import _isNaN from "./isNaN";
var exports = {};
var $isNaN = _isNaN;

/** @type {import('./sign')} */
exports = function sign(number) {
  if ($isNaN(number) || number === 0) {
    return number;
  }
  return number < 0 ? -1 : +1;
};
export default exports;